// npm
import React, { memo } from 'react'
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  withArtDirection,
} from 'gatsby-plugin-image'
import { Box, Container, styled, useMediaQuery, useTheme } from '@mui/material'
import SVG from 'react-inlinesvg'

// components
import BackgroundPlx from '@atoms/plx/BackgroundPlx'
import Div100vh from '@atoms/layout/Div100vh'
import FixedHeightHeroContainer from '@atoms/hero/FixedHeightHeroContainer'
import SectionText, { SectionTextProps } from '@molecules/text/SectionText'
import { createImageSectionTextDefaults } from 'helpers/createImageSectionTextDefaults'

const containerStyles = {
  position: 'relative',
  overflow: 'hidden',
  color: 'white',
}

// @ts-ignore
const FullHeightContainer = styled(Div100vh)(({ theme }) => ({
  ...containerStyles,
  background: theme.palette.secondary.main,
}))

// @ts-ignore
const StyledFixedHeightHeroContainer = styled(FixedHeightHeroContainer)(
  ({ theme }) => ({
    ...containerStyles,
    background: theme.palette.secondary.main,
  })
)

const BgImage = styled(GatsbyImage)(({ theme }) => ({
  opacity: 0.45,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'block',
    height: '0',
    paddingTop: '200%',
  },
}))

interface Props {
  keyId: string
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
  mainHeading: string
  subtitle: string
  text: string
  button?: Queries.DatoCmsButton
  iconUrl?: string
  hero?: boolean
  motif?: boolean
  forceHeadingBorder?: boolean
}

const ContentSectionBgImage = ({
  keyId,
  imageLandscape,
  imagePortrait,
  subtitle,
  mainHeading,
  text,
  button,
  iconUrl,
  hero,
  motif,
  forceHeadingBorder,
}: Props) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const sectionTextVariant = 'centered'

  let sectionTextProps: SectionTextProps = createImageSectionTextDefaults({
    sectionTextVariant,
    keyId,
    mainHeading,
    subtitle,
    text,
    button,
  })
  sectionTextProps.subtitleProps.color = 'white'
  // @ts-ignore
  sectionTextProps.subtitleProps.borderType =
    hero || forceHeadingBorder ? 'bottom' : null
  // @ts-ignore
  sectionTextProps.buttonLinkProps.colorVariant = 'white-text'
  // @ts-ignore
  sectionTextProps.mainHeadingProps.typographyProps.variant = hero ? 'h1' : 'h2'
  sectionTextProps.textBoxProps = { mt: 4 }

  if (hero) {
    // @ts-ignore
    sectionTextProps.textBoxProps = { typography: 'heroText' }
  }

  const images = withArtDirection(
    // @ts-ignore
    getImage(imageLandscape),
    [
      {
        media: `(max-width: ${theme.breakpoints.values.md}px)`,
        image: getImage(imagePortrait),
      },
    ]
  )

  let parallaxValues = {
    startValue: hero ? 0 : -50,
    endValue: 50,
  }
  if (mdDown) {
    parallaxValues = {
      ...parallaxValues,
      endValue: 50,
    }
  }

  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          ...parallaxValues,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  const content = (
    <Box bgcolor="common.black" height="100%">
      <BackgroundPlx parallaxData={parallaxData}>
        <BgImage image={images} alt="" />
      </BackgroundPlx>
      <Container
        maxWidth={false}
        className={hero ? 'fixed-height' : ''}
        sx={{ height: '100%', position: 'relative' }}
      >
        <Box
          className={hero ? 'fixed-height' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 1,
            height: 1,
            py: 10,
          }}
        >
          {iconUrl && (
            <Box mb={3} sx={{ svg: { width: '100px', height: 'auto' } }}>
              <SVG src={iconUrl} />
            </Box>
          )}
          <SectionText {...sectionTextProps} motif={motif} />
        </Box>
      </Container>
    </Box>
  )

  return (
    <>
      {hero ? (
        <StyledFixedHeightHeroContainer>
          {content}
        </StyledFixedHeightHeroContainer>
      ) : (
        <FullHeightContainer>{content}</FullHeightContainer>
      )}
    </>
  )
}

export default memo(ContentSectionBgImage)
